import React from "react";
//import { Link } from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Timeline from "../../components/timeline";

const History = ({location}) => (
  <Layout location={location}>
    <SEO title="History" />
    <Timeline lang="en" height="2000"
      items = {[
        {
          title: "Chaffey Community College",
          type: "education",
          side: "right",
          start: "2004-08-19",
          end: "2007-12-19",
          color: "#a80532",
          content: "Associate Degree: University Studies",
          bullets: [
            "GPA: 3.99",
            "Relevant Classes: Maya, Asian history",
            "Fulfilled general education requirements at any public California university."
          ]
        },
        {
          title: "Morrowind Graphics Extender",
          type: "project",
          side: "right",
          start: "2008-09-13",
          end: "2010-06-27",
          color: "#663D00",
          content: "This open source project significantly enhances the graphics of the game \"Morrowind\" by redirecting DirectX function calls.",
          bullets: [
            "Optimized code using memory and thread pooling, quadtree culling, and more.",
            "Increased by 500%, making it a feature that most users could enable at all times",
            "Wrote vertex and pixel shaders to enable animated grass and water effects."
          ]
        },
        {
          title: "Durham Technical Community College",
          type: "education",
          side: "right",
          start: "2011-08-15",
          end: "2012-05-08",
          color: "#00843D",
          content: "Relevant Classes: Calculus"
        },
        {
          title: "Wake Technical Community College",
          type: "education",
          side: "right",
          start: "2012-05-16",
          end: "2014-07-29",
          color: "#014165",
          content: "Associate degree: Pre-Engineering",
          bullets: [
            "GPA：4.0",
            "Relevant Classes: Calculus, Linear Algebra, Physics, Java, SolidWorks (CAD)"
          ]
        },
        {
          title: "North Carolina State University",
          type: "education",
          side: "right",
          start: "2014-08-21",
          end: "2017-05-13",
          color: "red",
          content: "Bachelor Degree: Computer Science - Game Development Concentration",
          bullets: [
            "GPA: 4.0",
            "Relevant Classes: Game / AI, Game Engine, 3D Graphics, Discrete Mathematics, Data Structure, Operating System, Creative Writing",
            "Programming Languages Used: C, C++, C#, Assembly, GLSL, JavaScript, Java",
            "Created a VR game using Unity and Google Cardboard as part of four-student team."
          ]
        },
        {
          title: "Passed the Japanese Language Proficiency Test level N3",
          type: "education",
          side: "right",
          start: "2017-12-01",
          end: "2017-12-01",
          color: "#d7123b "
        },
        {
          title: "Gameboy Emulator",
          type: "project",
          side: "right",
          start: "2018-09-16",
          end: "2019-08-05",
          color: "grey",
          content: "As a personal challenge, I created an emulator from scratch using C++.",
          bullets: [
            "Developed using publicly available specifications",
            "Tested with demos and homebrew games that were released for free by their creators",
            "Included the ability to visualize audio wave forms using Dear ImGui UI"
          ]
        },
        {
          title: "NifTools",
          type: "project",
          side: "left",
          start: "2005-09-01",
          end: "2007-12-01",
          color: "darkorange",
          content: "This open source project allows anyone to modify 3D models of games made with the Gamebryo game engine. ",
          bullets: [
            "Reverse engineered the NIF file format data structures.",
            "Developed Maya importer and exporter.",
            "Created a C++ library that can import and export NIF files."
          ]
        },
        {
          title: "Emergent Game Technologies",
          type: "work",
          side: "left",
          start: "2008-01-01",
          end: "2010-11-30",
          color: "#9ca534",
          content: "This company developed a C++ 3D game engine called Gamebryo. ",
          bullets: [
            "Wrote demos and samples, and improved developer tools.",
            "Solved problems for developers and artists from beginners to AAA studios.",
            "Debugged code on PS3, XBox 360, and Wii.",
            "Traveled to Japan to meet with customers."
          ]
        },
        {
          title: "Siemens Healthineers",
          type: "work",
          side: "left",
          start: "2010-12-01",
          end: "2018-07-31",
          color: "#009999",
          content: "This company develops a 3D medical laboratory software called Process Manager.",
          bullets: [
            "Executed a complete redesign of the C# WPF UI.",
            "Created 3D models using 3ds Max.",
            "Wrote a GLSL shader for a WebGL prototype."
          ]
        },
        {
          title: "Promoted to Senior Software Engineer",
          type: "work",
          side: "left",
          start: "2014-01-01",
          end: "2014-01-01",
          color: "#009999"
        },
        {
          title: "JET Program",
          type: "work",
          side: "left",
          start: "2018-07-29",
          end: "2020-09-15",
          color: "darkblue",
          content: "Taught English to high school students in Japan.",
          bullets: [
            "Got used to Japanese life and business culture.",
            "Visualized the complex school schedule with a Node.js script."
          ]
        }
      ]}
    />
  </Layout>
);

export default History;
